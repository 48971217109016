<template>
  <div class="company-intro">
    <h2>公司简介</h2>
    <el-input type="textarea" :rows="6" placeholder="请输入公司简介" @change="updatefrofile" v-model="intro"></el-input>



<!--    <h2>轮播图</h2>-->
<!--    <el-upload
      :action="uploadImageUrl + '/util/updataImage'"
      :on-preview="handlePicturePreview"
      :on-remove="handlePictureRemove1"
      :before-upload="beforePictureUpload"
      :on-success="uploadImgSuccess"
      :disabled="pictureList.length >= 5"
    >
      <el-button icon="el-icon-plus" size="small" type="primary">添加图片</el-button>

      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2MB</div>
    </el-upload>

    <el-dialog :visible.sync="previewVisible">
      <img width="100%" :src="previewImage" alt="" />
    </el-dialog>
    <div class="uploaded-pictures">
      <template v-for="(picture, index) in pictureList">
        <img :key="index" :src="picture" alt="" @click="handlePicturePreview1(picture)" />
        <i class="el-icon-delete" @click="handlePictureRemove(index,picture)"></i>
      </template>
    </div>-->
  </div>






</template>

<script>
import { ElEditor, ElButton } from 'element-ui';
import questionBank from "@/api/questionBank";

export default {
  name:'comp',
  data() {
    return {
      uploadImageUrl: process.env.VUE_APP_UPLOAD_IMAGE_URL,
      intro: '',
      previewVisible: false,
      previewImage: '',
      pictureList: [],

      loading: true,
      updateComp:{
        profile:'',
        slideshow:''
      },

    };
  },

  created() {
    this.getCompInfo()
    this.getCompInfoImg()
  },
  methods: {

    getCompInfo(){
      questionBank.getCompInfo().then((resp) => {
        if (resp.code === 200) {
          this.intro = resp.data

          this.loading = false

        }
      })
    },
    getCompInfoImg(){
      questionBank.getCompInfoImg().then((resp) => {
        if (resp.code === 200) {
          this.pictureList = resp.data.split(",")
          //console.log(this.pictureList)





        }
      })
    },
    updatefrofile(profile){
      this.updateComp.profile = profile
      questionBank.updateCompInfo(this.updateComp).then((resp) => {
        if (resp.code === 200) {
          this.getCompInfo()
          this.getCompInfoImg()
          this.$notify({
            title: 'Tips',
            message: '更新成功',
            type: 'success',
            duration: 2000
          })



        }
      })

    },
    handlePicturePreview(file) {
      this.previewImage = file.response.data
      this.previewVisible = true;
    },
    handlePicturePreview1(file){
      this.previewImage = file
      this.previewVisible = true;
    },
    handlePictureRemove1(index) {
      this.pictureList.splice(index, 1);
    },
    removeImg(){

    },

    handlePictureRemove(index,picture) {
      console.log(index+picture)
      this.updateComp.slideshow = this.pictureList.splice(index+1,1).join(',');
      console.log(this.updateComp.slideshow)
      questionBank.removeImg(this.updateComp).then((resp)=>{
        if (resp.code === 200) {
          this.getCompInfo()
          this.getCompInfoImg()
          this.$notify({
            title: 'Tips',
            message: '更新成功',
            type: 'success',
            duration: 2000
          })


        }

      })

    },
    beforePictureUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJpgOrPng) {
        this.$message.error('只能上传jpg/png文件');
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过2MB');
      }

      return isJpgOrPng && isLt2M;
    },
    uploadImgSuccess(response, file, fileList){

      const x = this.pictureList.push(response.data)
      console.log(this.pictureList)
      this.updateComp.slideshow = this.pictureList.join(",")
      questionBank.removeImg(this.updateComp).then((resp)=>{
        if (resp.code === 200) {
          this.getCompInfo()
          this.getCompInfoImg()
          this.$notify({
            title: 'Tips',
            message: '更新成功',
            type: 'success',
            duration: 2000
          })


        }

      })
    },
  },
};
</script>

<style scoped>
.company-intro {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  margin-top: 30px;
}

.uploaded-pictures img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  object-fit: cover;
  cursor: pointer;
}

.el-icon-delete {
  position: relative;
  top: -95px;
  right: -95px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.el-dialog__wrapper {
  z-index: 9999 !important;
}

</style>
